/*=================Scss Indexing=============
1.variables
2.typography
3.spacing
4.reset
5.forms
6.mixins
7.shortcode
8.animations
9.text-animation
10.sal
11.header
12.mobile-menu
13.button
14.nav
15.banner
16.swiper
17.funfacts
18.cta
19.about
20.common
21.service
22.projects
23.working-process
24.blog
25.blog-details
26.footer
27.search-input
28./side-bar
29.team
30.testimonials
31.faq
32.pricing
33.date-picker
34.time-picker
35.appoinment
36.awesome-feedback
37.contact
38.pre-loader.scss
39.back-to-top



==============================================  */


/* Default  */

@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/shortcode';
@import'default/animations';
@import'default/text-animation';

/* header  */
@import'header/header';
@import'header/nav';
@import'header/dropdown';
@import'header/sidebar';
@import'header/mobile-menu';

/* elements  */
@import'elements/banner';
@import'elements/button';
@import'elements/nice-select';
@import'elements/service';
@import'elements/about';
@import'elements/revel';
@import'elements/portfolio';
@import'elements/testimonials';
@import'elements/blog';
@import'elements/search_input';
@import'elements/back-to-top';
@import'elements/funfacts';
@import'elements/working-process';
@import'elements/cta';
@import'elements/footer';
@import'elements/appoinment';
@import'elements/cart';
@import'elements/brand';
@import'elements/progress';
@import'elements/team';
@import'elements/video';
@import'elements/contact';
@import'elements/blog-details';
@import'elements/history';
@import'elements/custom-map';
@import'elements/offer';
@import'elements/safety';
@import'elements/story';
@import'elements/gallery';
@import'elements/shop';
@import'elements/pricing';
@import'elements/what-we-offer';
@import'elements/why-choose';
@import'elements/career';
@import'elements/cart-page';
@import'elements/checkout';
@import'elements/account';
@import'elements/pagination';
@import'elements/privacy-policy';
@import'elements/loader';
@import'elements/faq';
@import'elements/rtl';