
// ravel animation start form hear
// espam
.reveal-item {
    position: relative;
    display: block;
    overflow: hidden;
  }
  .reveal-item .reveal-animation {
    position: absolute;
    top: 0;
    width: 100%;
    height: 101%;
    background: var(--color-primary);
    border-radius: 10px;
    overflow: hidden;
  }
  .reveal-item .reveal-animation::before {
    position: absolute;
    content: "";
    top: 0;
    border-radius: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bs-gray-400);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
  }
  
  .reveal-animation.reveal-primary::before {
    background: #0c0c0a;
  }
  
  .reveal-animation.reveal-dark::before {
    background: #000;
  }
  
  .reveal-animation.reveal-white::before {
    background: #000;
  }
  
  .reveal-animation.reveal-top.aos-animate::before,
  .reveal-animation.reveal-bottom.aos-animate::before {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  
  .reveal-animation.reveal-start.aos-animate::before,
  .reveal-animation.reveal-end.aos-animate::before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  
  .reveal-animation.reveal-top::before {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  
  .reveal-animation.reveal-start::before {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  
  .reveal-animation.reveal-end::before {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  
  .reveal-animation.reveal-bottom::before {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  
  [data-aos=reveal-top],
  [data-aos=reveal-start],
  [data-aos=reveal-end],
  [data-aos=reveal-bottom] {
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-delay: 1s;
            transition-delay: 1s;
  }
  
  [data-aos=reveal-top] {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  [data-aos=reveal-top].aos-animate {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  
  [data-aos=reveal-start] {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  [data-aos=reveal-start].aos-animate {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  
  [data-aos=reveal-end] {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  [data-aos=reveal-end].aos-animate {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  
  [data-aos=reveal-bottom] {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  [data-aos=reveal-bottom].aos-animate {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  
  [data-aos=reveal-item] {
    visibility: hidden;
    -webkit-transition-property: visibility;
    transition-property: visibility;
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
  }
  [data-aos=reveal-item].aos-animate {
    visibility: visible;
  } 