.rts-btn {
    font-size: 16px;
    font-weight: 700;
    // padding: 17px 28px;
    background-color: transparent;
    max-width: max-content;
    padding: 12px 28px;
    min-width: max-content;
    &.btn-header {
        background: var(--color-primary);
        padding: 21px 40px;
        font-weight: 600;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &.btn-primary {
        color: #fff;
        font-weight: 700;
        background: var(--color-primary);
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: var(--color-secondary);
            transform: translateY(110%);
            transition: 0.3s;
            transition-delay: 0.1s;
            z-index: -1;
        }

        &:hover {
            &::after {
                transform: translateY(0);
            }
        }
    }

    &.btn-primary-alta {
        color: var(--color-primary);
        font-weight: 700;
        background: rgba(239, 235, 231, 0.6);
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        transition: 0.3s;
        transition-delay: 0.1s;

        &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: var(--color-primary);
            transform: translateY(110%);
            transition: 0.3s;
            transition-delay: 0.1s;
            z-index: -1;
        }

        &:hover {
            color: #fff;

            &::after {
                transform: translateY(0);
            }
        }
    }

    &.btn-white {
        color: var(--color-primary);
        font-weight: 700;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        transition: 0.3s;
        transition-delay: 0.1s;

        img {
            transition: 0.3s;
            transition-delay: 0.1s;
        }

        &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: var(--color-primary);
            transform: translateY(110%);
            transition: 0.3s;
            transition-delay: 0.1s;
            z-index: -1;
        }

        &:hover {
            color: #fff;

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(197deg) brightness(106%) contrast(106%);
            }

            &::after {
                transform: translateY(0);
            }
        }
    }

    &.border {
        border: 2px solid var(--color-primary) !important;
        background: transparent;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: var(--color-primary);
            transform: translateY(110%);
            transition: 0.3s;
            transition-delay: 0.1s;
            z-index: -1;
        }

        img {
            transition: 0.3s;
            transition-delay: 0.1s;
        }

        &:hover {
            color: #fff;

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(197deg) brightness(106%) contrast(106%);
            }

            &::after {
                transform: translateY(0);
            }
        }
    }
}


.read-more-btn {
    color: var(--color-heading-dark);
    display: flex;
    align-items: center;
    gap: 10px;
    transition: .3s;
    font-weight: 700;
    &:hover {
        color: var(--color-primary);
    }
}