// service area start

.single-fun-facts-area {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    display: block;
    z-index: 1;
    height: 100%;

    @media #{$smlg-device} {
        padding: 30px;
    }

    &::after {
        content: '';
        position: absolute;
        left: -20%;
        top: -30%;
        width: 255px;
        height: 252.06px;

        background: radial-gradient(100% 100% at 50% 0%, rgba(255, 102, 0, 0) 0%, #FF6600 100%);
        opacity: 0.23;
        filter: blur(125px);
        z-index: -1;

    }

    .icons {
        margin-bottom: 45px;
    }

    .title {
        color: var(--color-primary);
        font-weight: 400;
        margin-bottom: 15px;
        font-size: 72px;

        @media #{$smlg-device} {
            font-size: 42px;
        }

        span {
            color: #141416;
            font-weight: 800;
        }
    }
}

.g-24 {
    --bs-gutter-x: 2.4rem;
    --bs-gutter-y: 2.4rem;
}


.title-center-style-one {
    text-align: center;

    .pre {
        font-size: 16px;
        font-weight: 600;
        color: var(--color-primary);
        text-transform: uppercase;
        margin-bottom: 13px;
        display: block;
    }

    .title {
        font-size: 44px;
        font-weight: 700;

        @media #{$large-mobile} {
            font-size: 26px;
        }
    }
}

.single-service-one {
    background: #1E1E20;
    padding: 40px;
    border-radius: 10px;
    position: relative;

    @media #{$md-layout} {
        padding: 20px;
    }

    @media #{$large-mobile} {
        padding: 15px;
    }

    .water-mark {
        color: #28282E;
        position: absolute;
        right: 40px;
        top: 75px;
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 700;

        @media #{$md-layout} {
            font-size: 24px;
        }

        @media #{$small-mobile} {
            display: none;
        }
    }

    .icon {
        height: 99px;
        width: 99px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #141416;
        margin-bottom: 40px;
        border-radius: 10px;
        transition: .3s;
    }

    .inner {
        background: #141416;
        padding: 30px;
        border-radius: 10px;

        .title {
            margin-bottom: 16px;
            font-size: 22px;
        }

        p {
            color: #7C7C84;
            margin-bottom: 16px;
            font-size: 15px;
        }

        a {
            &.read-more-narrow {
                color: #F84E1D;
            }
        }
    }

    &:hover {
        .icon {
            background: #F84E1D;
        }
    }
}


.swiper-area-wrapper {
    position: relative;

    .swiper-button-next,
    .swiper-button-prev {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1E1E20;
        border: 1px solid #34343A;
        border-radius: 10px;
        transition: .3s;

        &::after {
            display: none;
        }

        i {
            color: #fff;
        }

        &:hover {
            background: var(--color-primary);
            border-color: var(--color-primary);
        }
    }

    .swiper-button-next {
        right: -100px;
        left: auto;

        @media #{$md-layout} {
            right: -27px;
        }
    }

    .swiper-button-prev {
        left: -100px;
        right: auto;

        @media #{$md-layout} {
            left: -27px;
        }
    }
}

.mySwiper-service-one {
    position: relative;

}

.marque-area-wrapper {
    width: 130%;

    span {
        font-size: 180px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        color: #1B1B1E;
    }
}

.title-wrapper-left {
    span.pre {
        color: var(--color-primary);
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
    }

    .title {
        color: #141416;
        font-size: 44px;
        margin-top: 15px;

        @media #{$md-layout} {
            font-size: 32px;
        }

        @media #{$sm-layout} {
            font-size: 28px;
        }

        @media #{$large-mobile} {
            font-size: 26px;

            br {
                display: none;
            }
        }
    }
}

.single-choose-us-one {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 40px;

    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .icon {
        height: 90px;
        min-width: 90px;
        border-radius: 50%;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        span {
            position: absolute;
            height: 30px;
            width: 30px;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: #FFFFFF;
            border: 1px solid #E4DED8;
            box-shadow: 0px 4px 29px rgba(57, 46, 35, 0.18);
            color: #F84E1D;
            font-weight: 600;
        }
    }

    .title {
        color: #141416;
        font-size: 22px;
        margin-bottom: 10px;
    }

    p.disc {
        color: #7C7C84;
    }
}

.service-single-wrapper {
    padding: 50px;
    background: #fff;
    border-radius: 10px;

    @media #{$mdsm-layout} {
        padding: 25px;
    }

    .icon {
        height: 90px;
        background: #EFEBE7;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
    }

    .title {
        color: #141416;
        font-size: 22px;
        margin-top: 33px;
        margin-bottom: 15px;
    }

    p.disc {
        margin-bottom: 30px;
    }
}

.tab-area-service-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    @media #{$mdsm-layout} {
        justify-content: flex-start;
        gap: 10px;
    }

    li.nav-item {
        width: max-content;
        flex-basis: 24.5%;
        margin: 0;

        @media #{$mdsm-layout} {
            flex-basis: 21.5%;
        }

        button.nav-link {
            background: #FFFFFF;
            height: 80px;
            display: block;
            width: 100%;
            font-weight: 600;
            color: #141416;
            border: none;
            box-shadow: none;
            font-size: 20px;

            @media #{$mdsm-layout} {
                font-size: 18px;
            }

            @media #{$sm-layout} {
                height: 64px;
                font-size: 16px;
            }

            &:hover {
                border: none;
                box-shadow: none;
            }

            &.active {
                background: #F84E1D;
                color: #fff;
            }
        }
    }
}

.service-content-inner-wrapper {
    background-image: url(../images/service/02.webp);
    padding: 80px;
    border-radius: 0 0 10px 10px;

    @media #{$large-mobile} {
        padding: 30px;
    }

    .inner {
        max-width: 63%;

        @media #{$mdsm-layout} {
            max-width: 100%;
        }

        .title {
            font-size: 30px;

            @media #{$large-mobile} {
                font-size: 22px;
            }
        }

        p.disc {
            color: #7C7C84;
            margin-bottom: 28px;
        }

        .wrapper-check-area-start {
            max-width: 400px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 45px;
            row-gap: 15px;

            .single {
                display: flex;
                align-items: center;
                gap: 15px;

                p {
                    color: #ffffff;
                    margin: 0;
                    font-size: 16px;
                }

                i {
                    font-size: 18px;
                    color: var(--color-primary);
                }
            }
        }

        .rts-btn {
            margin-top: 35px;

            &::after {
                background: #fff;
            }

            &:hover {
                color: var(--color-primary);
            }
        }
    }
}

.cta-wrapper-inner {
    .rts-btn {
        &::after {
            background: #141416;
        }
    }
}

.container-1730 {
    max-width: 1730px;
    margin: auto;
}

.service-bg_image-3 {
    border-radius: 10px;
    height: 406px;
    background-image: url(../images/service/03.webp);
}

.mt-dec--130 {
    margin-top: -130px;
}

.single-service-area-3 {
    padding: 40px;
    position: relative;
    background: #fff;
    overflow: hidden;
    display: block;
    border-radius: 10px;
    height: 100%;

    .icon {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 0 0 10px 0;
        height: 106px;
        width: 106px;
        background: var(--color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span.number {
        position: absolute;
        color: #EFEBE7;
        right: 50px;
        top: 50px;
        font-size: 70px;
        font-weight: 700;
        font-family: var(--font-secondary);
    }

    a .title {
        margin-top: 100px;
        color: #141416;
        font-size: 22px;
        margin-bottom: 10px;
        transition: .3s;

        &:hover {
            color: var(--color-primary);
        }
    }

    p.disc {
        margin-bottom: 10px;
        font-size: 15px;
        color: #7C7C84;
    }

    .read-more-btn {
        color: var(--color-primary);
        font-weight: 600;
        max-width: max-content;

        i {
            transition: .3s;
        }

        &:hover {
            i {
                margin-left: 5px;
            }
        }
    }
}

.title-center-wrapper-4 {
    text-align: center;

    .title {
        font-size: 44px;
        line-height: 1.2;

        @media #{$sm-layout} {
            font-size: 32px;
        }
        @media #{$small-mobile} {
            font-size: 26px;
        }
    }
}

.single-service-style-4 {
    background: #141416;
    border-radius: 10px;
    overflow: hidden;

    .top {
        padding: 40px;
        @media #{$small-mobile} {
            padding: 25px;
        }
        .icon {
            height: 80px;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #1E1E20;
            border: 1px solid #2E2E31;
            border-radius: 5px;
            margin-bottom: 26px;
        }

        .title {
            margin-bottom: 7px;
        }

        p.disc {
            color: #7C7C84;
            margin-bottom: 0;
        }
    }

    .thumbnail {
        position: relative;
        display: block;
        overflow: hidden;
        z-index: 1;
        border-radius: 10px;

        &::after {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 0%;
            bottom: 0;
            background: var(--color-primary);
            z-index: 0;
            transition: .3s;
        }

        .hidden-content {
            position: absolute;
            left: 50%;
            top: 50%;
            min-width: max-content;
            transform: translate(-50%, -50%) scale(0);
            z-index: 1;
            transition: .3s;

            .rts-btn {
                height: 45px;

                &::after {
                    background: #141416;
                }
            }
        }
    }

    &:hover {
        .thumbnail {
            &::after {
                height: 100%;
            }
        }

        .hidden-content {
            transform: translate(-50%, -50%) scale(1);
        }
    }
}

.rts-service-area-five {
    background: #1B1B1E;
}

.wrapper-column-service-area {
    height: 100%;
}

.service-main-wrapper-5 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$smlg-device} {
        flex-wrap: wrap;
        justify-content: center;

        &>div {
            flex-basis: 50%;
        }
    }

    @media #{$sm-layout} {
        flex-wrap: wrap;
        justify-content: center;

        &>div {
            flex-basis: 100%;
        }
    }
}

.single-service-area-five {
    padding: 60px;
    width: 540px;
    border: 1px solid #2E2E31;
    height: 50%;
    height: 354px;
    position: relative;
    z-index: 1;

    @media #{$laptop-device} {
        width: 440px;
    }

    @media #{$smlg-device} {
        flex-basis: 100%;
        width: 100%;
    }

    @media #{$sm-layout} {
        padding: 40px;
    }

    &:hover {
        &::after {
            opacity: 0.2;
        }

        .icon-area {
            &::after {
                background: rgba(248, 78, 29, 1);
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        width: 87px;
        height: 87px;
        left: 70px;
        top: 70px;
        background: #F84E1D;
        mix-blend-mode: normal;
        opacity: 0.2;
        filter: blur(30px);
        z-index: -1;
        transition: .3s;
        opacity: 0;
    }

    &.bottom-border-none {
        border-bottom: none;
    }

    .icon-area {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 25px;

        position: relative;
        z-index: 1;

        &::after {
            content: '';
            position: absolute;
            width: 50px;
            height: 50px;
            left: -15px;
            top: -22px;
            background: rgba(248, 78, 29, 0.1);
            border-radius: 5px;
            z-index: -1;
            transition: .3s;
        }

        .icon {
            width: 87px;
            height: 87px;
            background: #141416;
            border: 1px solid #2E2E31;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p {
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: #28282E;

            @media #{$laptop-device} {
                font-size: 36px;
            }

            @media #{$large-mobile} {
                font-size: 22px;
            }
        }
    }

    a {
        .title {
            color: #fff;
            font-size: 22px;
            margin-bottom: 12px;
            transition: .3s;
        }

        &:hover {
            .title {
                color: var(--color-primary);
            }
        }
    }

    p.disc {
        color: #7C7C84;
        margin-bottom: 25px;
    }

    a.read-more {
        color: var(--color-primary);
        font-weight: 600;
    }
}

.service-wrapper-bg-mid-5 {
    background-image: url(../images/service/07.webp);
    width: 100%;
    height: 709px;
    display: flex;
    align-items: center;

    @media #{$large-mobile} {
        height: 500px;
    }
}

.service-inner-title-wrapper-five {
    text-align: center;
    max-width: 529px;
    margin: auto;

    span.pre {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        color: var(--color-primary);
    }

    .title {
        text-transform: uppercase;
        font-size: 44px;
        margin-top: 10px;

        @media #{$laptop-device} {
            font-size: 24px;
        }

        @media #{$smlg-device} {
            text-transform: uppercase;
            font-size: 28px;
            margin-top: 10px;
            line-height: 1.4;
        }
    }

    .rts-btn {
        margin: auto;
    }

    p.disc {
        font-size: 18px;
        color: #7C7C84;
        line-height: 1.6;
    }
}

.single-service-area-inner {
    overflow: hidden;
    border-radius: 10px;
    display: block;

    .thumbnail {
        display: block;
        overflow: hidden;

        img {
            transition: .3s;
        }
    }

    .innr {
        padding: 30px;
        background: #fff;

        .title {
            color: var(--color-title);
            transition: .3s;
            margin-bottom: 14px;

            &:hover {
                color: var(--color-primary);
            }
        }

        p.disc {
            font-size: 16px;
            margin-bottom: 20px;
        }

        .check-wrapper {
            .single {
                display: flex;
                align-items: center;
                gap: 13px;

                i {
                    color: var(--color-title);
                }

                p {
                    margin-bottom: 0;
                    color: var(--color-title);
                    font-weight: 500;
                }
            }
        }

        a.btn {
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--color-title);
            transition: .3s;
            font-size: 18px;
            max-width: max-content;
            padding: 0;
            margin: 0;
            margin-top: 25px;

            &:hover {
                color: var(--color-primary);
                gap: 15px;
            }
        }
    }

    &:hover {
        .thumbnail {
            img {
                transform: scale(1.05);
            }
        }
    }
}

.service-main-wrapper-tabs {
    border-radius: 15px;
    overflow: hidden;
    display: block;
    background: #fff;

    .nav {
        display: flex;
        align-items: center;
        background: #fff;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #D9D9D9;

        li {
            margin: 0;
            padding: 0;
            border: none !important;

            button {
                padding: 21px 25px;
                border-color: transparent !important;
                text-transform: uppercase;
                font-weight: 500;
                color: #141416;
                font-size: 18px;
                @media #{$large-mobile} {
                    padding: 10px;
                }
                &.active{
                    background-color: transparent;
                    color: var(--color-primary);
                }
            }
        }
    }

    .tab-content {
        .inner-wrapper-tab-service-wrapper {
            padding: 40px;
            @media #{$large-mobile} {
                padding: 20px;
            }
            .single {
                display: flex;
                align-items: center;
                gap: 25px;
                margin-bottom: 35px;
                @media #{$large-mobile} {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 25px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                .icon{
                    height: 50px;
                    min-width: 50px;
                    background: #F6F2F0;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i{
                        color: var(--color-primary);
                    }
                }
                .inner-content{
                    font-size: 18px;
                    @media #{$large-mobile} {
                        font-size: 16px;
                    }
                    b{
                        color: #141416;
                    }
                }
            }
        }
    }
}

.service-details-inner-area-wrapper{
    margin-top: 30px;
    .title{
        color: var(--color-title);
        font-size: 40px;
    }
}

.call-to-action-area-service{
    height: 393px;
    background-image: url(../images/cta/03.webp);
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding-left: 100px;
    @media #{$sm-layout} {
        height: auto;
        padding: 40px;
    }
    @media #{$large-mobile} {
        padding: 25px;
    }
    .inner{
        max-width: 60%;
        @media #{$sm-layout} {
            max-width: 100%;
        }
        .title{
            font-size: 40px;
            @media #{$sm-layout} {
                font-size: 32px;
            }
        }
        p.disc{
            color: #FFFFFF;
        }
    }
}