// gallery-image popup

#gallery {
    padding-top: 40px;

    @media screen and (min-width: 991px) {
        padding: 0;
    }
}

.img-wrapper {
    position: relative;
    margin-top: 15px;

    img {
        width: 100%;
    }
}

.img-overlay {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;

    i {
        color: #fff;
        font-size: 3em;
        cursor: pointer;
    }
}

.coming-soon-body{
    background: #fff;
}

#overlay {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    // Removes blue highlight
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    img {
        margin: 0;
        width: 80%;
        height: auto;
        object-fit: contain;
        padding: 5%;

        @media screen and (min-width:768px) {
            width: 60%;
        }

        @media screen and (min-width:1200px) {
            width: 50%;
        }
    }
}

#nextButton {
    color: #fff;
    font-size: 2em;
    transition: opacity 0.8s;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    @media screen and (min-width:768px) {
        font-size: 3em;
    }
}

#prevButton {
    color: #fff;
    font-size: 2em;
    transition: opacity 0.8s;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    @media screen and (min-width:768px) {
        font-size: 3em;
    }
}

#exitButton {
    color: #fff;
    font-size: 2em;
    transition: opacity 0.8s;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    @media screen and (min-width:768px) {
        font-size: 3em;
    }
}


.project-details-carousel-big {
    overflow: hidden;
    position: relative;
}