.single-history-area {
    .year-history {
        width: 80px;
        height: 80px;
        background: var(--color-title);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 40px;

        span {
            font-size: 20px;
            font-weight: 700;
            color: #fff;
        }
    }

    .body-history {
        border-radius: 10px;
        background: #fff;
        text-align: center;

        .icon {
            height: 100px;
            width: 100px;
            background: #FFF5F2;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-bottom: 20px;
            margin-top: -50px;
            z-index: 10;
            position: relative;
        }

        .title {
            color: var(--color-title);
            font-size: 22px;
            margin-bottom: 12px;
        }

        p {
            margin-bottom: 0;
            max-width: 100%;
            margin: auto;
            padding-bottom: 30px;
            max-width: 80%;
        }
    }
}

.position-history-relative {
    position: relative;
    z-index: 1;

    hr.history {
        position: absolute;
        left: 0;
        top: 85px;
        z-index: -1;
    }
}

hr.history {
    width: 100%;
    height: 1px;
    background: #D9D9D9;
}

.bg-color-dark-1 {
    background: #1E1E20;
}

