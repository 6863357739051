// blog style

.rts-blog-card-one {
    background: #fff;
    display: block;
    overflow: hidden;
    border-radius: 10px;

    .thumbnail {
        display: block;
        overflow: hidden;
        position: relative;

        .tag-area {
            display: flex;
            align-items: center;
            position: absolute;
            left: 0;
            bottom: 0;
            background: #fff;
            border-radius: 0 10px 0 0;
            overflow: hidden;

            span {
                padding: 9px 25px;
                font-weight: 700;
                font-size: 15px;
                color: #141416;

                &.location {
                    background: var(--color-primary);
                    color: #fff;
                }
            }
        }

        img {
            transition: .3s;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    .inner-wrapper {
        padding: 30px;
        @media #{$large-mobile} {
            padding: 15px;
        }
        a .title {
            color: var(--color-heading-dark);
            font-size: 22px;
            margin-bottom: 0;
            padding-bottom: 22px;
            border-bottom: 1px solid #EDECEC;
            margin-bottom: 22px;
            transition: .3s;

            &:hover {
                color: var(--color-primary);
            }
        }

        p.disc {
            margin-bottom: 25px;
            font-size: 15px;
        }
    }
}


.blog-list-card-wrapper {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    @media #{$large-mobile} {
        padding: 20px;
    }
}

.single-blog-card-2 {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EDECEC;
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
    }

    .thumbnail {
        display: block;
        overflow: hidden;
        max-width: 180px;
        border-radius: 10px;
        @media #{$sm-layout} {
            max-width: 100%;
        }
        img {
            transition: .3s;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    .inner-content {
        .top-area {
            display: flex;
            align-items: center;
            gap: 19px;
            margin-bottom: 10px;

            .technology {
                display: block;
                background: #EDECEC;
                padding: 2px 16px;
                font-weight: 500;
                color: #141416;
                font-size: 15px;
                border-radius: 100px;
            }

            .date {
                color: #7C7C84;
                font-weight: 500;
            }
        }

        .body-content {
            .title {
                color: var(--color-title);
                font-size: 22px;
                margin-bottom: 12px;
                transition: .3s;
                @media #{$large-mobile} {
                    font-size: 20px;
                }
                &:hover {
                    color: var(--color-primary);
                }
            }

            p.disc {
                margin-bottom: 15px;
            }

            .read-more-btn {
                font-weight: 600;
                color: var(--color-primary);

                i {
                    transition: .3s;
                }

                &:hover {
                    i {
                        margin-left: 3px;
                    }
                }
            }
        }
    }

    &.colum {
        flex-direction: column;
        position: relative;
        padding: 10px;
        background: #fff;
        border-radius: 10px;
        height: 100%;

        .thumbnail {
            max-width: 100%;
        }

        .inner-content {
            position: absolute;
            left: 30px;
            bottom: 30px;

            .top-area {
                .date {
                    color: #fff;
                }
            }

            .body-content {
                .title {
                    color: #fff;
                }

                p.disc {
                    color: #fff;
                    max-width: 80%;
                }
            }
        }
    }
}

.blog-signle-style-four {
    background: #141416;
    padding: 20px;
    border-radius: 10px;

    .thumbnail {
        max-width: 100%;
        display: block;
        overflow: hidden;
        border-radius: 10px;

        img {
            width: 100%;
            transition: .3s;
        }
    }

    .inner-content {
        padding: 30px 10px 10px;
        @media #{$large-mobile} {
            padding: 30px 0 0;
        }
        .top-area {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 15px;

            .tag {
                color: #F84E1D;
                font-weight: 500;
                background: #EDECEC;
                display: block;
                padding: 4px 15px;
                border-radius: 100px;
                font-size: 15px;
            }

            span.date {
                color: #fff;

            }
        }

        .title {
            font-size: 30px;
            transition: .3s;
            @media #{$sm-layout} {
                font-size: 24px;
            }

            &:hover {
                color: var(--color-primary);
            }
        }

        p.disc {
            color: #fff;
            max-width: 90%;
            margin-bottom: 20px;
        }

        .rts-btn-read-more {
            display: flex;
            gap: 5px;
            align-items: center;
            color: var(--color-primary);

            i {
                transition: .3s;
            }

            &:hover {
                i {
                    margin-left: 5px;
                }
            }
        }
    }

    &:hover {
        .thumbnail {
            img {
                transform: scale(1.05);
            }
        }
    }
}

.rts-btn.btn-primary.border.style-two {
    border-color: #363639 !important;
    height: 50px;
    transition: .3s;

    &:hover {
        border-color: var(--color-primary) !important;
    }
}

.single-blog-card-style-5 {
    background: #FFFFFF;
    border: 1px solid #EBE4E0;
    border-radius: 10px;

    &.small-blog-five {
        .author-area {
            display: none !important;
        }

        .title-content-area {
            padding-left: 0 !important;
        }
    }

    .top-area-blog {
        padding: 20px;

        span {
            padding: 0 0 15px 0;
            display: block;
            text-align: center;
            border-bottom: 1px solid #EBE4E0;
            margin-bottom: 20px;
            text-transform: uppercase;
            color: #141416;
            font-size: 14px;
        }

        .thumbnail {
            border-radius: 10px;
            display: block;
            overflow: hidden;

            img {
                transition: .5s;
            }
        }
    }

    .inner-content-area {
        border-top: 1px solid #EBE4E0;
        padding: 40px;
        display: flex;
        align-items: center;
        overflow: hidden;
        @media #{$sm-layout} {
            padding:  30px 20px;
        }
        .author-area {
            display: flex;
            align-items: center;
            gap: 20px;
            flex-basis: 23%;
            position: relative;
            @media #{$laptop-device} {
                flex-wrap: wrap;
            }
            @media #{$smlg-device} {
                flex-wrap: wrap;
            }
            @media #{$mdsm-layout} {
                display: none;
            }
            &::after {
                position: absolute;
                content: '';
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 229px;
                width: 3px;
                background: #EBE4E0;
            }

            img {
                max-width: 60px;
            }

            p {
                margin-bottom: 0;
                font-weight: 800;
                font-size: 14px;
                text-transform: uppercase;
                color: #141416;
            }

            .name {
                font-size: 14px;
                color: #141416;
                font-size: 18px;
                margin-bottom: 0;
            }
        }

        .title-content-area {
            padding-left: 40px;
            @media #{$sm-layout} {
                padding-left: 0;
            }
            .tag-area {
                display: flex;
                align-items: center;
                gap: 34px;
                margin-bottom: 16px;
                @media #{$small-mobile} {
                    flex-wrap: wrap;
                    gap: 15px;
                }
                span {
                    color: #7C7C84;
                    text-transform: uppercase;
                    font-size: 14px;
                }

                span.intro {
                    color: var(--color-primary);
                    font-size: 14px;
                    font-weight: 500;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        left: -20px;
                        height: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 5px;
                        background: #EAEAEA;
                        border-radius: 50px;
                    }
                }
            }

            .title {
                color: #141416;
                font-size: 24px;
                text-transform: uppercase;
                transition: .3s;
                @media #{$sm-layout} {
                    font-size: 18px;
                    transition: 0.3s;
                    line-height: 1.4;
                }
                &:hover {
                    color: var(--color-primary);
                }
            }

            .read-more {
                text-transform: uppercase;
                color: #141416;
                font-size: 14px;
                font-weight: 700;
                text-decoration: underline;
                transition: .3s;

                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }

    &:hover {
        .thumbnail {
            img {
                transform: scale(1.1);
            }
        }
    }
}