.rts-appoinment-area-3 {
    .appoinment-three-wrapper {
        background: #141416;
        border-radius: 20px;

        .thumbnail {
            overflow: hidden;
            display: block;
            border-radius: 20px;
        }
    }
}

.appoinment-area-main-form {
    padding-right: 100px;
    padding-left: 90px;
    @media #{$mdsm-layout} {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @media #{$sm-layout} {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media #{$small-mobile} {
        padding-left: 15px;
        padding-right: 15px;
    }
    .title {
        font-size: 36px;
        margin-bottom: 40px;
        display: block;
        @media #{$large-mobile} {
            font-size: 22px;
        }
    }

    form {
        .half-input-wrapper {
            display: flex;
            align-items: center;
            gap: 20px;
            width: 100%;
            margin-bottom: 20px;

            .single-input {
                width: 100%;
                .nice-select {
                    display: flex;
                    align-items: center;
                }
                input,
                .nice-select {
                    height: 55px;
                    background: #1E1E20;
                    border: 1px solid transparent;
                    color: #fff;
                    border-radius: 5px;
                    margin-bottom: 0 !important;
                    &:focus {
                        border: 1px solid var(--color-primary);
                    }
                }
            }
        }

        textarea {
            background: #1E1E20;
            height: 160px;
            border: 1px solid transparent;
            color: #fff;
            margin-bottom: 20px;
            border-radius: 5px;
            padding: 15px;
            &:focus {
                border: 1px solid var(--color-primary);
            }
        }

        button {
            border: none;
        }
    }
}