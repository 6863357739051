// dropdown style
li.has-dropdown {
    position: relative;

    .submenu {
        min-width: 230px;
        height: auto;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 90;
        opacity: 0;
        visibility: hidden;
        text-align: left;
        transition: 0.3s;
        border-radius: 0 0 6px 6px;
        background-color: #fff;
        border-left: 0;
        border-bottom: 0;
        border-right: 0;
        display: inline-block;
        box-shadow: 0 36px 35px rgba(0, 0, 0, 0.08);
        padding: 15px 10px;
        transform-origin: 0 0;
        transform: scaleY(0);

        li {
            margin-right: 0;
            padding: 0;

            a {
                padding: 6px 16px !important;
                font-size: 16px;
                transition: all .3s;
                border-radius: 4px;
                display: block;
                padding: 12px 45px 12px 15px;
                border-radius: 5px;
                background: #FFFFFF;
                transition: all 0.3s;
                text-align: left;
                font-size: 14px;
                font-weight: 600;

                &:hover {
                    // background: var(--color-primary);
                    opacity: 1;
                    background: #F2F2F8;
                    opacity: 1;
                    color: var(--color-primary) !important;
                }
            }
        }
    }

    &:hover {
        .submenu {
            opacity: 1;
            visibility: visible;
            top: 100%;
            transform: translateY(0);
            transform: scaleY(1);
        }

        &::after {
            content: "\f077";
        }
    }
}

.has-dropdown.mega-menu::after {
    display: none;
}

.rts-btn.btn-primary.border.bg-transparent {
    color: var(--color-primary);
    border: 1px solid var(--color-primary) !important;

    img {
        filter: brightness(0) saturate(100%) invert(46%) sepia(55%) saturate(5974%) hue-rotate(228deg) brightness(98%) contrast(103%);
    }

    &:hover {
        background: var(--color-primary) !important;
        color: #fff;

        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7500%) hue-rotate(241deg) brightness(114%) contrast(108%);
        }
    }
}


header .nav-area ul li.main-nav {
    &:hover {
        &>a {
            transition: .3s;
            color: var(--color-primary) !important;
        }

        &::after {
            color: var(--color-primary) !important;
        }
    }
}

.rts-mega-menu {
    position: absolute;
    width: 100%;
    height: auto;
    top: 100%;
    transform: scaleY(0);
    left: 0;
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    transition: all 0.3s;
    border-radius: 0 0 5px 5px !important;
    background-color: #ffffff;
    display: inline-block;
    box-shadow: 0 36px 35px rgba(61, 60, 60, 0.08);
    transform-origin: 0 0 0;
    padding: 30px 30px;

    // box-shadow: 0px 25px 60px 0px rgba(0, 0, 0, 0.1);
    &.with-add {
        padding: 0;
        overflow: hidden;
        border-radius: 0 0 10px 10px;

        .menu-add-top-area {
            padding: 30px 0 25px 0;
            border-bottom: 1px solid #E1E1FF;
            margin-left: 50px;

            .title {
                margin-bottom: 0;
                font-size: 24px;
                color: var(--color-primary);
            }
        }

        .menu-right-add {
            display: flex;
            justify-content: flex-end;
            align-items: end;
            max-width: max-content;
            margin-left: auto;
            position: relative;
            border-radius: 0 0 7px 0;
            overflow: hidden;

            .absolute-image {
                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                }

                .inner-content {
                    position: absolute;
                    left: 23px;
                    bottom: 23px;

                    .title {
                        color: #fff;
                        font-size: 24px;
                    }

                    .rts-btn {
                        background: #fff;
                        color: var(--color-primary);
                    }
                }
            }
        }

        .mega-menu-item {
            // padding: 15px 30px 40px 25px;

            li a {
                display: flex;
                align-items: center;
                gap: 5px;
                width: 100%;

                img {
                    max-width: 30px;
                    height: auto;
                    padding: 0;
                    background: transparent;
                    transition: .3s;
                    margin-right: 5px;
                }

                &:hover {
                    img {
                        filter: brightness(0) saturate(100%) invert(97%) sepia(3%) saturate(0%) hue-rotate(288deg) brightness(103%) contrast(100%);
                    }
                }

                &.active {
                    img {
                        filter: brightness(0) saturate(100%) invert(97%) sepia(3%) saturate(0%) hue-rotate(288deg) brightness(103%) contrast(100%);
                    }
                }
            }
        }
    }
}

.mega-menu-item {
    padding: 0;
    margin: 0;
    flex-direction: column;
    display: flex;
    align-items: flex-start !important;

    li {
        margin-bottom: 19px;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        width: 100%;

        &:hover {
            a {
                border: 1px solid #E6E5FF;
                border-radius: 4px;
            }
        }

        a {
            display: flex !important;
            align-items: center;
            padding: 6px 12px !important;
            border: 1px solid transparent;
            width: 100%;

            img {
                margin-right: 16px;
                padding: 10px;
                max-width: max-content;
                background: #F0F0FF;
                border-radius: 4px;
            }

            .info {
                p {
                    margin-bottom: 2px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    color: #083A5E;
                }

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #497696;
                }
            }
        }
    }
}

.has-dropdown.mega-menu {
    position: static !important;
}

.has-dropdown.mega-menu {
    &:hover {
        .rts-mega-menu {
            opacity: 1;
            visibility: visible;
            top: 100%;
            transform: scaleY(1);
        }
    }
}

.container-full-header {
    .rts-mega-menu {
        transform: translateX(-50%) scaleY(0);
        left: 50%;
        max-width: 80%;

        @media #{$laptop-device} {
            max-width: 90%;
        }
    }

    .has-dropdown.mega-menu:hover .rts-mega-menu {
        transform: translateX(-50%) scaleY(1);
    }
}

.has-dropdown.mega-menu {
    &:hover {
        &>a {
            &::after {
                content: "\f077";
                color: var(--color-primary);
            }
        }
    }
}

.appoinment-area-main.contact-page {
    border-radius: 10px;
    background: #F9F8FF;
    border: 1px solid #DDD8F9;
    background-image: none;

    input,
    textarea,
    .custom-select {
        color: var(--color-primary) !important;
        border-color: var(--color-primary) !important;

        span {
            color: var(--color-primary) !important;
        }
    }

    .rts-btn {
        background: var(--color-primary);
        color: #fff;
    }
}

header.header-three .rts-mega-menu.service-mega-menu-style {
    width: 75%;
}

.rts-mega-menu.service-mega-menu-style {
    padding: 30px 30px 0 30px;
    z-index: 1000;

    li {
        margin-bottom: 20px;

        a {
            border: none;
            width: 100%;
            padding: 18px 25px !important;
            border-radius: 5px;
            background: #f9f9f9;
            display: block;
            transition: .3s;

            &.free-consultation {
                background: var(--color-primary);

                .title {
                    color: #fff !important;
                }

                p.details {
                    color: #fff !important;
                }

                &:hover {
                    background: var(--color-primary);
                }
            }

            .single-service-menu {
                display: flex;
                align-items: flex-start;

                .icon {
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 15px;

                    img {
                        background: none;
                        padding: 0;
                        max-width: 40px;
                        margin: auto;
                        filter: brightness(0) saturate(100%) invert(39%) sepia(62%) saturate(3233%) hue-rotate(349deg) brightness(98%) contrast(99%);
                    }
                }

                .title {
                    color: var(--color-title);
                    margin-bottom: 11px;
                    line-height: 17px;
                }

                p.details {
                    max-width: 100%;
                    font-size: 14px;
                    color: var(--color-body);
                }
            }
        }

        &:hover a {
            border: none;
            background: #fff3f3;
        }
    }

    .menu-thumb {
        height: auto;

        img {
            height: auto;
            object-fit: cover;
        }
    }
}

header .menu-item-open {
    >a {
        color: var(--color-primary) !important;

        &::after {
            content: "\f077" !important;
            color: var(--color-primary) !important;
        }
    }
}

header .menu-item-open.project-a-after>a::after {
    display: none;
}


.menu-item-open {
    &::after {
        color: var(--color-primary) !important;
    }
}

.mega-menu-item.with-list {
    gap: 0;

    li {
        a {
            border: none !important;
            display: flex;
            align-items: center;
            gap: 8px;
            border-bottom: 1px solid #f3f3f3 !important;
            padding: 12px 12px !important;
            transition: .3s;

            i {
                font-size: 12px;
            }

            &.active {
                color: var(--color-primary);
                border: none !important;
                gap: 12px;
                border-radius: 0 !important;
                border-bottom: 1px solid var(--color-primary) !important;
            }
        }

        &:hover {
            a {
                border: none !important;
                gap: 12px;
                border-radius: 0 !important;
                border-bottom: 1px solid var(--color-primary) !important;
            }
        }
    }
}


.sub-dropdown.down {
    position: relative !important;
    display: block !important;

    &::after {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        content: '\f054';
        font-family: var(--font-3);
    }

    .submenu.third-lvl.base {
        opacity: 0 !important;
        visibility: hidden;
        min-width: 225px !important;
        left: 100% !important;
        top: -25px !important;
        margin: 0;
        border-radius: 0 !important;
        border-radius: 10px !important;
        transition: .3s;
        display: block !important;
    }

    &:hover {
        &::after {
            color: var(--color-primary);
        }

        .submenu.third-lvl.base {
            opacity: 1 !important;
            top: 0 !important;
            right: 0 !important;
            visibility: visible;
            margin-left: 0 !important;
            min-width: 225px !important;
        }
    }
}

li.has-dropdown .submenu li a {
    &.active {
        color: var(--color-primary);
        background: #F2F2F8;
    }
}

.rts-mega-menu.service-mega-menu-style li a {
    &.active {
        background: #fff3f3;
    }
}

.rts-mega-menu.service-mega-menu-style li a {
    &.active.free-consultation {
        background: var(--color-primary);
    }
}

header.header-two .menu-item-open>a {
    color: var(--color-primary) !important;
}

.header-four {
    .nav-area .has-demo-down>a::after {
        color: #ffffff;
    }
}

.heder-one {
    .nav-area .has-demo-down>a::after {
        color: #ffffff;
    }
}

.nav-area {
    .has-demo-down {
        position: unset;

        &::after {
            display: none;
        }

        .submenu {
            &.menu-home {
                flex-wrap: wrap;
                min-width: 1413px !important;
                justify-content: center;
                margin-left: 0;
                margin-top: 0;
                padding: 25px;
                border-radius: 0;
                left: 50%;
                top: 83%;
                transform: translateX(-50%) scaleY(0);
                justify-content: flex-start;
                display: flex;
                gap: 10px;

                @media #{$laptop-device} {
                    min-width: 100% !important;
                }

                @media #{$smlg-device} {
                    min-width: 100% !important;
                }

                li {
                    width: 19.4% !important;
                    margin: 0;
                    position: relative;
                    overflow: hidden;
                    margin-right: 0 !important;

                    @media #{$laptop-device} {
                        margin-right: 0 !important;

                        min-width: 19% !important;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.829) 0%, rgba(0, 0, 0, 0.418) 35%, rgba(0, 212, 255, 0) 100%);
                        opacity: 0;
                        width: 100%;
                        height: 100%;
                        transition: all 0.5s;
                        bottom: -100%;
                    }

                    .page {
                        display: block;
                        position: absolute;
                        bottom: 50%;
                        right: 50%;
                        transform: translate(50%, 50%) scale(0);
                        opacity: 0;
                        transition: all 0.3s;
                        z-index: 1;

                        li {
                            width: 100% !important;
                            padding: 0;

                            &::after {
                                display: none;
                            }

                        }

                        li .rts-btn {
                            margin: 0 auto 10px auto;
                            padding: 5px 15px !important;
                            width: 100%;
                            max-width: 100%;
                            border-radius: 0;
                            font-size: 12px;
                            line-height: 20px;
                            color: #000;
                            text-transform: uppercase;
                        }
                    }

                    a {
                        span {
                            transition: .3s;
                        }
                    }

                    &:hover {
                        .page {
                            opacity: 1;
                            transform: translate(50%, 50%) scale(1);
                        }

                        &::after {
                            opacity: 0;
                            bottom: 0;
                        }

                        a {
                            span {
                                color: var(--color-primary);
                                letter-spacing: .7px;
                            }
                        }
                    }
                }

                &:hover {
                    li {
                        a {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &:hover {
            .submenu {
                &.menu-home {
                    opacity: 1;
                    visibility: visible;
                    top: 100%;
                    transform: translateX(-50%) scaleY(1) !important;
                }
            }
        }

        &>a {
            position: relative;

            &::after {
                position: absolute;
                content: "\f078";
                right: -22px;
                top: 51%;
                transform: translateY(-50%);
                font-weight: 400;
                color: #111032;
                font-family: "Font Awesome 6 Pro";
                font-weight: 300;
                font-size: 14px;
            }
        }

        &:hover {
            &>a {
                &::after {
                    color: var(--color-primary);
                    content: "\f077";
                }
            }

            &::after {
                display: none;
            }
        }
    }
}

li.coming-soon {
    a img {

        pointer-events: none;
        cursor: none;
        // filter: blur(2px);
    }
}

li.has-dropdown .submenu.menu-home li a {
    padding: 6px 5px !important;
}

.header-five .nav-area .has-demo-down>a::after {
    color: #ffffff;
}