.rts-elevate-pagination {
    ul {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        list-style: none;

        li {
            margin: 0;
            margin-right: 10px;

            button {
                width: 50px;
                height: 50px;
                background: transparent;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #000;
                border: 1px solid var(--color-white);
                transition: .3s;
                background: #fff;
                &.active {
                    background: var(--color-primary);
                    color: #fff;
                    border-color: var(--color-primary);
                }

                &:hover {
                    background: var(--color-primary);
                    color: #fff;
                    border-color: var(--color-primary);
                }
            }
        }
    }
}